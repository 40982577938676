import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fc2b9cf1b038257bb9a8e2ace7a779c4@o1207633.ingest.us.sentry.io/4508618596089856",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});